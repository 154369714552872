import { useTranslate } from '@xFrame4/components/Hooks';
import SmoothImage from '@xFrame4/components/common/SmoothImage';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

interface Custom404Props
{

}

const Custom404: FunctionComponent<Custom404Props> = () =>
{
    const t = useTranslate();
    
    return (
        <section className="bg-home d-flex align-items-center mt-5 pb-env">
            <Container className="pb-5 mb-5">
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="text-center">
                        <div className="text-uppercase mt-4 display-3">{t('404_OH_NO')}</div>
                        <div className="text-capitalize text-dark mb-4 error-page">
                            {t('404_TITLE')}
                        </div>
                        <p className="text-muted para-desc mx-auto">
                            {t('404_DESCRIPTION')}
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className="text-center">
                        <Link href="/">
                            <a className="btn btn-primary mt-4 ms-2">{t('404_GO_TO_HOME')}</a>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Custom404;